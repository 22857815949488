
// import request from 'public/src/pages/product_app/request.js'
// const { langPath = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
import schttp from 'public/src/services/schttp'

// 所有接口暴露，方便引入 （文件还是要区分，入口统一而已，防止文件过深）
export * from './products.js'
export * from './cccIndex.js'
export * from './cmp.js'
export * from './order.js'
export * from './upload.js'
export * from './integral.js'

/**
 * 
 * @param {*} page 
 * @returns 
 */
export const getLanguage = async (page) => {
  if (!page) {
    return Promise.resolve({
      code: -1,
      msg: 'miss page'
    })
  }

  return await schttp({
    method: 'GET',
    url: `/api/common/language/get`,
    params: {
      page
    }
  })
}
