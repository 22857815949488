import schttp from 'public/src/services/schttp'

// 获取是否可以上传试用报告
export const getUploadTryStatus = async (billno) => {
  const json = await schttp({
    method: 'POST',
    url: '/api/orders/base/queryUploadTryStatus/query',
    data: {
      billno_list: [billno]
    },
  })

  let data = {}

  if (json.code == 0 && json.info && json.info.upload_try_status) {
    const res = json.info.upload_try_status
    data = res.find(i => i.billno == billno) || {}
  } 

  return data
}

// 获取订单信息
export const getOrderDetailInfo = async (billno) => {
  const body = await schttp({
    method: 'GET',
    url: '/api/orders/base/get_order_info/get',
    params: {
      billno
    }
  })

  let data = {}

  if (body.code == 0) {
    data = body.info?.order || {}
  }
  return data
}
